<template>
  <div class="pickup-container">
    <div class="pickup-success" v-if="success">
      <img :src="`/static/${tipData.success ? 'success' : 'warning'}.png`" />
      <h2>{{title}}</h2>
      <p class="c-gray">{{tipData.content}}</p>
      <van-button block round type="warning" v-if="!tipData.success" @click="selfDelivery" :loading="loading">确定</van-button>
    </div>
    <div class="pickup-box">
      <div class="pickup-title">等待上门取件</div>
      <div class="pickup-item">
        <label required>取件日期：</label>
        <div class="item-value" @click="visible = true">{{earliestPickupDate}}</div>
      </div>
      <div class="pickup-btn">
        <van-button type="warning" block round :disabled="!postData.earliestPickupDate" @click="pickup" :loading="loading">提交</van-button>
      </div>
    </div>

    <van-popup v-model="visible" position="bottom">
      <datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirm"
      />
    </van-popup>
  </div>
</template>

<script type="text/javascript">
import order from '@/api/order'
import { DatetimePicker } from 'vant'
import dayjs from 'dayjs'
export default {
  components: { DatetimePicker },
  data() {
    return {
      success: false,
      loading: false,
      visible: false,
      postData: {
        earliestPickupDate: ''
      },
      tipData: {
        content: '选择自投至网点将不会有快递员上门取件'
      },
    }
  },
  computed: {
    title() {
      return this.$route.params.type == 1 ? '等待上门取件' : '自投至网点'
    },
    minDate() {
      var date = new Date()
      date.setDate(date.getDate() + 1)
      return date
    },
    maxDate() {
      var date = new Date()
      date.setDate(date.getDate() + 4)
      return date
    },
    earliestPickupDate() {
      return this.postData.earliestPickupDate ? dayjs(this.postData.earliestPickupDate).format('YYYY年MM月DD日') : '请选择'
    }
  },
  created() {
    if (this.$route.params.type == 2) {
      this.success = true
      this.tipData = {
        content: '选择自投至网点将不会有快递员上门取件',
        success: false
      }
    }
  },
  methods: {
    selfDelivery() {
      this.loading = true
      order.pickupSelf(this.$route.params.itemId)
      .then(() => {
        this.tipData.success = true
        this.$toast.success('提交成功')
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
    },
    confirm(value) {
      this.postData.earliestPickupDate = dayjs(value).format('YYYY-MM-DD')
      this.visible = false
    },
    pickup() {
      this.$dialog.confirm({
        title: '提示',
        confirmButtonText: '我同意',
        cancelButtonText: '我拒绝',
        cancelButtonColor: '#ee0a24',
        confirmButtonColor: '#1880ff',
        message: '第三方上门揽件是我司提供的增值服务，如第三方未按时上门取件，请拨打: 0771333310进行咨询。或尽快把贴好我司面单的文件投递到最近的Postnord网点，网点扫码后，可在sweden.stoepx.com内追踪。',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            order.pickup(this.$route.params.itemId, this.postData)
            .then(() => {
              this.$toast.success('提交成功')
              this.tipData = {
                title: '等待上门取件',
                content: '预约取件日期：' + this.postData.earliestPickupDate,
                success: true
              }
              this.success = true
              done()
            })
            .catch(() => {
              done()
            })
          }
          else {
            order.pickupSelf(this.$route.params.itemId)
            .then(() => {
              this.tipData.success = true
              this.$toast.success('提交成功')
              done()
            })
            .catch(() => {
              done()
            })
          }
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.pickup {
  &-container {
    background-image: url(/static/login_bg.png);
    background-size: cover 230px;
    background-repeat: repeat-x;
    padding: 93px 15px 30px;
    background-color: #F0F4F6;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100vh;
  }
  &-success {
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 1;
    padding: 100px 30px;
    text-align: center;
    img {
      width: 48px;
    }
    h2 {
      font-size: 26px;
      color: #1A1A1A;
      margin-top: 14px;
      margin-bottom: 12px;
    }
    p {
      margin-bottom: 50px;
    }
  }
  &-box {
    background: #fff;
    border-radius: 12px;
    padding: 50px 25px 70px;
    position: relative;
  }
  &-title {
    font-size: 28px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 22px;
  }
  &-item {
    position: relative;
    margin-bottom: 12px;
    label {
      font-size: 12px;
      color: #999;
      display: block;
      position: relative;
      &[required]:before {
        position: absolute;
        content: '*';
        color: #F56C6C;
        font-size: 14px;
        left: -7px;
        margin-top: 2px;
      }
    }
    .item-value {
      font-size: 14px;
      color: #333;
      line-height: 22px;
      padding: 10px 0;
    }
  } 
  &-btn {
    margin-top: 30px;
    &-tip {
      margin-top: 6px;
      text-align: right;
      a {
        color: @orange;
      }
    }
    .van-button {
      border-radius: 12px;
    }
  }
  &-ft {
    color: #B8B8B8;
    text-align: center;
    margin-top: 30px;
  }
}
</style>
